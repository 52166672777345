import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Card, Text } from '@components';
import { PRICING_LINK } from '@shared/constants/constants';
import { GA4_EVENTS } from '@shared/constants/google';

const CrossedSpan = styled.span`
  text-decoration: line-through;
`;

const DealsPlanInfoCard = ({ plan }) => {
  const { t } = useTranslation();

  const {
    badgeAmount,
    coupon,
    currency,
    discountPercentage,
    extraTime,
    price,
    pricePerMonth,
    regularPrice,
    regularPricePerMonth,
    subscriptionPeriod,
  } = plan;

  return (
    <Card.DealsPlanCard
      duration={
        <Trans
          i18nKey="plans:checkoutCard.title"
          values={{
            title: t(`plans:checkoutCard.periodTitle.${subscriptionPeriod}`),
            extraComment: extraTime && t(`plans:monthsExtra.${extraTime}`),
          }}
          components={[<Text.Subheading2 as="span" color="primary" fontWeight="inherit" key={0} />]}
        />
      }
      price={pricePerMonth}
      pricePeriod={t('plans:checkoutCard.perMonthShort')}
      billingDescription={
        <Trans
          i18nKey={
            regularPrice
              ? 'plans:checkoutCard.billingDescription'
              : 'plans:checkoutCard.billingDescriptionWithoutRegularPrice'
          }
          values={{
            price,
            regularPrice,
            currency,
            periodicity: regularPrice
              ? t(`plans:checkoutCard.periodicity.${subscriptionPeriod}`)
              : t(`plans:checkoutCard.noDiscountPeriodicity.${subscriptionPeriod}`),
          }}
          components={[<CrossedSpan key={0} />]}
        />
      }
      currency={currency}
      description={t('plans:checkoutCard.noHiddenFees')}
      regularPricePerMonth={
        regularPricePerMonth
          ? t('plans:checkoutCard.regularPricePerMonth', {
              currency,
              regularPrice: regularPricePerMonth,
            })
          : null
      }
      highlightLabel={t('plans:checkoutCard.savingsAndRefund')}
      badgeText={
        !!discountPercentage &&
        !!badgeAmount && (
          <Trans
            i18nKey="plans:checkoutCard.saveMoney"
            components={[
              <Text.Body4Strong as="span" key={0} whiteSpace="pre-line" color="white" />,
            ]}
            values={{
              discount: `${currency} ${badgeAmount}`,
              period: t(`plans:checkoutCard.periodTitle.${subscriptionPeriod}`),
            }}
          />
        )
      }
      couponText={
        coupon && (
          <Trans
            i18nKey="plans:checkoutCard.couponCode"
            values={{ couponCode: coupon }}
            components={[<span key={0} />]}
          />
        )
      }
      button={{
        title: t('common:actions.getAtlasVpn'),
        link: PRICING_LINK,
        buttonProps: {
          customEvent: { eventName: GA4_EVENTS.footerPricingCtaClick },
        },
      }}
    />
  );
};

export default DealsPlanInfoCard;
